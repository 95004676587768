import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import Footer from "components/layout/Footer"
import Header from "components/layout/Header"
import HeaderBg from "components/layout/HeaderBg"
import LayoutBase from "components/layout/LayoutBase"
import SEO from "components/layout/SEO"
import Link from "components/ui/Link"
import MaxWidthWrapper from "components/ui/MaxWidthWrapper"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const About = (props: PageProps) => {
  return (
    <LayoutBase {...props}>
      <Header location={props.location} overflow="hidden" />
      <HeaderBg bgColor="primary.main" position="absolute" top="0" />
      <Flex flex="1 1 auto" flexDirection="column">
        <SEO title="MN: About" />
        <HeaderBg bgColor="white" />
        <MaxWidthWrapper pt={0}>
          <Flex
            justifyContent="space-between"
            flexDir={{ base: "column", md: "row" }}
          >
            <Box
              pb={6}
              display="flex"
              alignItems="center"
              flexDir="column"
              flex="1 0 auto"
            >
              <StaticImage
                quality={90}
                alt="Hi there!"
                src="../../assets/about-kalabaka.webp"
                placeholder="none"
                style={{
                  marginBottom: "48px",
                }}
                loading="eager"
                height={400}
                width={400}
                layout="constrained"
              />
            </Box>

            <Box pl={{ base: 0, md: 6 }}>
              <Heading textAlign="center" as="h1" fontSize="5xl">
                About me
              </Heading>
              <Text display="block" pt={6}>
                Hi there! My name is Michał Nowotnik. I come from Warsaw,
                Poland. In the picture you can see me sweating in{" "}
                <Link
                  href="https://en.wikipedia.org/wiki/Kalabaka"
                  isExternal={true}
                >
                  Kalabaka
                </Link>
                . My forte is applying the analytical process to anything:
                coding, designing, speaking, teaching, and even dancing. In this
                way I get results I am proud of. I work as a{" "}
                <Link fontWeight={500} href="/services" isGatsby={true}>
                  software consultant
                </Link>
                , but I'm also interested in topics such as open source,
                productivity, life optimization and learning.
              </Text>
            </Box>
          </Flex>
        </MaxWidthWrapper>
      </Flex>
      <Footer />
    </LayoutBase>
  )
}

export default About
